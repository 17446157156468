export const googlePayMethods = ['worldpay-googlepay', 'adyen-googlepay', 'nuvei-googlepay', 'routepay-googlepay']

export const scanCodePayMethods = ['adyen-pix', 'adyen-boleto', 'alipay-pix']
export const urlAll = ({
  type = 'place_order',
  children = 'detail'
}) => {
  const urlAll = {
    place_order: {
      // 没二次下单
      detail: '/user/orders/detail/',
      list: '/user/orders/list'
    },
    gift_card: {
      agin: '/giftcard/checkout/again/',
      detail: '/user/orders/giftcard_detail/',
      list: '/user/orders/giftcardlist/'
    },
    buy_xtra: {
      // 没有二次下单
      detail: '/user/orders/virtualDetail/',
      list: '/user/orders/list'
    }
  }
  return urlAll[type][children]
}

/**
 * 需调用前置路由的非卡支付路由
 */
export const PRE_ROUTING_PAYMENT_METHODS = [
  'routepay-konbini', 
  'routepay-pix', 
  'routepay-applepay', 
  'routepay-googlepay',
  'routepay-oxxo',
  'routepay-spei',
  'routepay-mercadopago',
  'routepay-yape',
  'routepay-multibanco'
]

/**
 * dlocal相关渠道的支付方式
 */
export const DLOCAL_PAYMENT_METHODS = [
  'dlocal-oxxo',
  'dlocal-boleto',
  'dlocal-pix',
  'dlocal-eft',
  'dlocal-pse',
  'dlocal-nupay',
  'dlocal-spei',
  'dlocal-mercadopago',
  'dlocal-yape',
]

export const MULTIBANCO_PAYMENT_METHODS = ['adyen-multibanco']
