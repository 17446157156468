<template>
  <div class="payment_item_cod_tips">
    <div
      ref="text"
      class="payment_item_cod_tips__txt"
      :class="{ payment_item_cod_tips__camp: canCollapse && collapse }"
    >
      {{ txt }}
    </div>
    <div
      v-if="canCollapse"
      class="payment_item_cod_tips__icon"
    >
      <!-- ICON组件不支持响应式 且不能v-if -->
      <Icon
        v-show="collapse"
        name="sui_icon_more_down_16px_2"
        size="16px"
      />
      <Icon
        v-show="!collapse"
        name="sui_icon_more_up_16px_2"
        size="16px"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
export default defineComponent({
  name: 'PaymentCampTxt',
  components: {
    Icon
  },
  emits: ['update:collapse'],
  props: {
    txt: {
      type: String,
      default: ''
    },
    collapse: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default: () => ({
        line: 2,
        lineHeight: 1.7
      })
    }
  },
  data() {
    return {
      canCollapse: false
    }
  },
  mounted() {
    // this.txtIsCamp = this.$el.scrollHeight > 48
    nextTick(() => {
      const textDom = this.$refs.text

      if (textDom) {
        // threshold =  line * lineHeight * fontSize
        let threshold =
          this.option.line *
          this.option.lineHeight *
          Math.ceil(parseFloat(window.getComputedStyle(textDom).fontSize))

        if (threshold < textDom.clientHeight) {
          this.canCollapse = true
          this.$emit('update:collapse', true)
        }
      }
    })
  },
})
</script>

<style scoped lang="less">
.payment_item_cod_tips {
  position: relative;
  padding-top: 8/75rem;
  line-height: 1.7;
  font-size: 20/75rem;
  font-weight: 400;
  text-transform: capitalize;
  .payment_item_cod_tips__txt {
    color: var(---sui_color_gray_dark2, #666);
    width: 702/75rem;
    padding-left: 64/75rem;
  }
  .payment_item_cod_tips__camp {
    position: relative;
    .line-camp();
    &:after {
      content: '  ...';
      position: absolute;
      .padding-l(30/75rem);
      bottom: 0;
      right: 0;
      width: 20 * 4/75rem; /* 字号*个数 */
      height: 20 * 1.7/75rem; /* 字号*lineHight */
      // background: #fff;
      color: rgba(0, 0, 0, 0.4);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        white 40% /* 调整这里的百分比值 */
      );
    }
  }
  .payment_item_cod_tips__icon {
    position: absolute;
    bottom: 0;
    right: 0;
    // backdrop-filter: blur(1/75rem);
  }
}
</style>
